<template lang="pug">
  div.mt-4
    p.fz14.bold.mb-1.mt-2(v-if="!(selectedCardId || canPayWithoutStripe)") カードを入力する
    div#card-element(v-show="!(selectedCardId || canPayWithoutStripe)")
    div#card-errors
    p.text-center.fz14.bold.mt-6.mb-0(v-show="isShowLoading && paymentType === 'prePayment'") カード情報の確認中...
    p.text-center.fz14.bold.mt-6.mb-0(v-show="isShowLoading && paymentType === 'advancedPayment'") お支払い中...
    v-checkbox.mt-1(v-show="!(selectedCardId || canPayWithoutStripe)" v-model="toSaveCard" :label="`このカード情報を記録する`")
    p.fz14.bold.mb0(v-if="paymentMethods && !canPayWithoutStripe") 保存済みのカードを使う
    v-radio-group.mt-0(v-if="paymentMethods" v-show="!canPayWithoutStripe" v-model="selectedCardId")
      v-radio.bold-label(
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.paymentMethodId"
        :value="paymentMethod.paymentMethodId"
        :label="`  ${paymentMethod.brand} ...${paymentMethod.last4} ${paymentMethod.month}/${paymentMethod.year}`"
        @click="removeDoubleSelectRadio"
      )
    MinNowLoading(:show="isShowLoading")
    div.f.fh.mt-8.mb-8(v-show="!isShowLoading")
      v-btn(color="#bdbdbd" width="176" v-if="!canPay")
        span.bold(style="color:#E7305B;" width="176") 確定
      v-btn(color="#E7305B" width="176" v-else @click="submitStripeCard")
        span.bold(style="color:#fff;" width="176") 確定
</template>

<style lang="scss">
@import "@/scss/_variables.scss";

.bold-label {
  .theme--light.v-label {
    font-size: 14px !important;
    font-weight: bold !important;
    color: #444 !important;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import { stripePublishKey } from '@/components/utils/config'
import { loadStripe } from '@stripe/stripe-js'
import firebase, { functions } from '@/components/utils/firebase'
import MinNowLoading from '@/components/shared/MinNowLoading'
import { database } from '../../../functions/shared/database'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    MinNowLoading
  },
  props: {
    order: null,
    paymentType: '',
    canPayWithoutStripe: false
  },
  data: () => ({
    stripe: null,
    validCard: false,
    canPay: false,
    paymentMethods: null,
    selectedCardId: null,
    selectedCardData: null,
    card: null,
    isShowLoading: false,
    toSaveCard: false,
    stripePublishKey: stripePublishKey
  }),
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    selectedCardId: function (val) {
      if (val) {
        const index = this.paymentMethods.findIndex(e => e.paymentMethodId === val)
        this.selectedCardData = this.paymentMethods[index]
      } else {
        this.selectedCardData = null
      }
      this.checkCanPay()
    },
    canPayWithoutStripe: function (val) {
      this.checkCanPay()
    },
    validCard: function (val) {
      this.checkCanPay()
    }
  },
  async mounted () {
    const stripeInfo = await database.secretCollection(this.uid).findById('stripe')
    if (stripeInfo) this.paymentMethods = stripeInfo.paymentMethods

    this.stripe = await loadStripe(this.stripePublishKey)
    const elements = this.stripe.elements()
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
    this.card = elements.create('card', { style: style })
    this.card.mount('#card-element')
    const self = this
    this.card.addEventListener('change', (event) => {
      self.validCard = event.complete
    })
  },
  methods: {
    removeDoubleSelectRadio () {
      if (this.selectedCardId && this.selectedCardData) {
        if (this.selectedCardId === this.selectedCardData.paymentMethodId) {
          this.selectedCardId = null
        }
      }
    },
    async submitStripeCard () {
      this.isShowLoading = true
      // サービス料含め支払いがないとき
      if (this.canPayWithoutStripe) {
        this.$emit('no-pay')
        return
      }
      if (this.selectedCardData) {
        const data = {
          id: this.selectedCardData.paymentMethodId,
          card: {
            last4: this.selectedCardData.last4,
            exp_year: this.selectedCardData.year,
            exp_month: this.selectedCardData.month,
            brand: this.selectedCardData.brand
          }
        }
        this.createStripePayment(data)
      } else {
        await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card
        }).then(res => {
          this.createStripePayment(res.paymentMethod)
        })
      }
    },
    async createStripePayment (paymentMethod) {
      let req
      if (this.paymentType === 'prePayment') {
        if (this.order.payOnlyCash) {
          req = {
            orderId: this.order.id,
            type: this.paymentType,
            amount: this.order.serviceFee + this.order.agencyFee - this.order.usedCouponAmount - this.order.usedPoint,
            paymentMethodId: paymentMethod.id,
            last4: paymentMethod.card.last4,
            year: paymentMethod.card.exp_year,
            month: paymentMethod.card.exp_month,
            brand: paymentMethod.card.brand,
            uid: this.uid,
            saveCard: this.toSaveCard
          }
        } else {
          const amount = this.order.serviceFee + this.order.agencyFee + Math.floor(this.order.budget * 1.09) - this.order.usedCouponAmount
          req = {
            orderId: this.order.id,
            type: this.paymentType,
            amount: amount,
            paymentMethodId: paymentMethod.id,
            last4: paymentMethod.card.last4,
            year: paymentMethod.card.exp_year,
            month: paymentMethod.card.exp_month,
            brand: paymentMethod.card.brand,
            uid: this.uid,
            saveCard: this.toSaveCard
          }
        }
      } else if (this.paymentType === 'advancedPayment') {
        let amount
        amount = this.order.serviceFee + this.order.agencyFee + Math.floor(this.order.advancedMoney * 1.09) + Number(this.order.chip)
        if (this.order.servicePaymentCoupon) amount -= this.order.servicePaymentCoupon
        if (this.order.usedPoint) amount -= this.order.usedPoint

        req = {
          orderId: this.order.id,
          type: this.paymentType,
          amount: amount,
          paymentMethodId: paymentMethod.id,
          last4: paymentMethod.card.last4,
          year: paymentMethod.card.exp_year,
          month: paymentMethod.card.exp_month,
          brand: paymentMethod.card.brand,
          uid: this.uid,
          saveCard: this.toSaveCard
        }
      }
      if (req.amount < 50) {
        window.alert('カード決済の最低額は50円からになります。お手数ですが50円以上になるようチップやボーナスの額を調整いただくようお願いいたします。')
        this.isShowLoading = false
        this.$emit('stripe', { paymentIntentId: 'lessThanPay' })
        return
      }
      const createPayment = functions.httpsCallable('createStripePayment')
      await createPayment({ req }).then((res) => {
        this.handleStripeResponse(res.data)
      })
    },
    handleStripeResponse (response) {
      if (response.error) {
        this.isShowLoading = false
        window.alert('決済時にエラーが発生しました。カードをお確かめの上、再度やり直してください。')
        console.error('response error', response.error)
      } else if (response.requires_action) {
        this.stripe.handleCardAction(response.payment_intent_client_secret)
          .then(this.handleStripeJsResult)
      } else {
        this.isShowLoading = false
        this.$emit('stripe', { paymentIntentId: response.id })
      }
    },
    async handleStripeJsResult (result) {
      if (result.error) {
        window.alert('決済時にエラーが発生しました。カードをお確かめの上、再度やり直してください。')
        console.error('result error', result.error)
      } else {
        const req = {
          orderId: this.order.id,
          type: this.paymentType,
          paymentIntentId: result.paymentIntent.id
        }
        const createPayment = functions.httpsCallable('createStripePayment')
        await createPayment({ req }).then((res) => {
          console.log('createPayment', req)
          this.handleStripeResponse(res.data)
        })
      }
    },
    checkCanPay () {
      this.canPay = (this.selectedCardData !== null ||
        this.canPayWithoutStripe ||
        this.validCard)
    }
  }
}
</script>
